import React, { useEffect, useRef, useState } from 'react'
import patientImg from '../../assets/images/dashboardPatientImg.svg'
import allergicIcon from '../../assets/images/allergicIcon.svg'
import facilityIcon from '../../assets/images/facilityIcon.svg'
import showIcon from '../../assets/images/showIcon.svg'
import pdfIcon from '../../assets/images/pdfIcon.svg'
import calender from '../../assets/images/calendar.svg'
import personAddIcon from '../../assets/images/personAddIcon.svg'
import closeIcon from '../../assets/images/closeIcon.svg'
import { Avatar, Tooltip } from '@mui/material'
import ButtonType from '../../components/button/ButtonType'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import SelectField from '../../components/inputFeild/SelectField'
import { useAppDispatch, useAppSelector } from '../../helpers/hook'
import { loginPageSlices } from '../loginPage/loginPageSlice'
import { DashboardSlices, setResetMessageValues, resetPatientSearchList, setPatientListClickedStatus } from './dashboardSlice'
import { IDashboardState, IMessageList, IPatientMessageHistory, IPatientSearchByName, IPatientSearchList, IPlaceOrderState, ISelectPreviousMsg, ISentMessage, ISingleMessage } from '../../models/dashboardInterfaces'
import { CommonSlices } from '../common/commonSlice'
import { fetchindividualList, handleSingleMessage, onHandleSentMessage, setPlaceOrderContent, patientSearchByName, fetchPatientMessageHistory, fetchSendDashboardMessage } from '../../services/dashboardServices'
import TextField from '../../components/inputFeild/TextFeild'
import { convertHtmlToString, filterDigitsOnly } from '../../helpers/validators'
import { MSG_GENERAL, MSG_NURSING_STAFF, MSG_PROVIDER, MSG_TYPE_GENERAL, NURSING_STAFF_SPACE, P_ACT_ALLOW, ROLE_CREATE_SEND_MESSAGES, ROLE_SEND_GENERAL_MESSAGES, ROLE_SEND_ORDER, USER_ROLE_PATIENTS, USER_ROLE_PROVIDER, USER_ROLE_RELATIVES, useHasActionPermission, MSG_TAB_NO_MESSAGE, MSG_TAB_NEW_MESSAGE, MSG_TAB_SEL_MESSAGE, MSG_TAB_SEND_MESSAGE, MSG_TYPE_MESSAGE, MSG_TYPE_ORDER, GENERAL, LBL_NURSING_STAFF, LBL_GENERAL, LBL_PROVIDERS, PROVIDER, PATIENTS, LBL_ASSOCIATES, MSG_CLIENT_ADMIN, MSG_CLIENT_ASSOCIATE, MSG_PATIENTS, MSG_RELATIVES, LBL_PATIENTS, PATIENTS_RELATIVES, RELATIVES, LBL_GENERAL_PATIENTS, CLIENT_ADMIN_ASSOCIATE, USER_ROLE_CLIENT_ASSOCIATE } from '../../config/permissions'
import dayjs from 'dayjs'
import Paginations from '../../components/Pagination'


// const modules = {
//   toolbar: [
//     ["bold", "italic", "underline"],
//     [{ list: "ordered" }, { list: "bullet" }],
//     [
//       { align: "" },
//       { align: "center" },
//       { align: "right" },
//       { align: "justify" },
//     ],
//     [{ 'header': [1, 2, false] }],
//   ],
// };

interface Option {
  label: string;
  value: string;
  group_name?: string;
  user_id?: number;
}

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownPatRef = useRef<HTMLDivElement>(null);
  const secondFieldRef = useRef<HTMLInputElement>(null);
  const { token, unid, client, logedUsername, logedUserID, logedUserProfilePic, loginUserRole } = useAppSelector(loginPageSlices);
  const { facilityData, navFacility } = useAppSelector(CommonSlices);
  const { toMessageList, resetMessage, selectedMessageTab, selectedSingleMsgUser, selectPreviousMsg, paginationTotalCount, placeOrderContent, patientSearchList, patientListClickedStatus, patientListClickedData, loading, sidebarSearch, sidebarfilterOption } = useAppSelector(DashboardSlices);
  const minMinutes = '30';
  const hasHighPriority = '2';
  const NOT_SELECTED_USER_ID = -1;
  const notAllowedUserIDs = [NOT_SELECTED_USER_ID, 0, -11];
  //PERMISSIONS
  const hasSentMessagePermission = useHasActionPermission(ROLE_CREATE_SEND_MESSAGES, P_ACT_ALLOW);
  const hasSentGeneralMessagePermission = useHasActionPermission(ROLE_SEND_GENERAL_MESSAGES, P_ACT_ALLOW);
  const hasSentOrderPermission = useHasActionPermission(ROLE_SEND_ORDER, P_ACT_ALLOW);
  // const hasViewGeneralMessagePermission = useHasActionPermission(ROLE_VIEW_GENERAL_MESSAGES, P_ACT_ALLOW);
  // const hasViewOrderPermission = useHasActionPermission(ROLE_VIEW_ORDER, P_ACT_ALLOW);
  //Paginaton
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25;


  const moreOptions: Option[] = [
    { label: LBL_NURSING_STAFF, value: MSG_NURSING_STAFF },
    { label: LBL_PROVIDERS, value: MSG_PROVIDER },
   // { label: LBL_PATIENTS, value: PATIENTS_RELATIVES },
    { label: LBL_ASSOCIATES, value: CLIENT_ADMIN_ASSOCIATE },
    // { label: LBL_GENERAL, value: MSG_GENERAL },
  ]

  
  const [selectedPatientSearchDetail, setSelectedPatientSearchDetail] = useState<Option[]>([]);

  const individualOptions: Option[] = [
    ...toMessageList.filter(option => option.email !== logedUsername).map((item) => ({
      label: item.user_name,
      value: item.email,
      group_name: item.group_name,
      user_id: item.user_id,
    })),
    ...selectedPatientSearchDetail,
  ];
    
  const selMessageToOptions = [
    { name: LBL_NURSING_STAFF, id: MSG_NURSING_STAFF },
    { name: LBL_PROVIDERS, id: MSG_PROVIDER },
    { name: LBL_PATIENTS, id: PATIENTS_RELATIVES },
    { name: LBL_ASSOCIATES, id: CLIENT_ADMIN_ASSOCIATE }
  ];
  const filteredOptions = selMessageToOptions.filter(option => individualOptions.some(individualOption => {
    if (option.id === PATIENTS_RELATIVES) {
      return individualOption.group_name === MSG_PATIENTS || individualOption.group_name === MSG_RELATIVES
    } else if (option.id === CLIENT_ADMIN_ASSOCIATE) {
      return individualOption.group_name === MSG_CLIENT_ADMIN || individualOption.group_name === MSG_CLIENT_ASSOCIATE
    }
    else { return individualOption.group_name === option.id }
  }
  ));




  const [selMessageToType, setSelMessageToType] = useState<string>('');
  const [extraSpace, setExtraSpace] = useState<boolean>(false);
  const [messageType, setMessageType] = useState<number>(MSG_TYPE_MESSAGE);
  const [messageTab, setMessageTab] = useState<number>(MSG_TAB_NO_MESSAGE);
  const [messageValidation, setmessageValidation] = useState<boolean>(false);
  const [messageToValidation, setmessageToValidation] = useState<boolean>(false);
  const [selectPatientValidation, setSelectPatientValidation] = useState<boolean>(false);
  // const [timeTakenValidation, setTimeTakenValidation] = useState<boolean>(false);
  const [priority, setPriority] = useState<string>("1");
  const [selectedToMessage, setSelectedToMessage] = useState<Option[]>([]);
  const [manageToMessage, setManageToMessage] = useState<Option[]>([]);
  const [searchToMessage, setSearchToMessage] = useState<string>('');
  const [showToMessage, setshowToMessage] = useState<boolean>(false);
  const [showPatientList, setshowPatientList] = useState<boolean>(false);
  const [initialRendering, setinitialRendering] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const [timeTaken, setTimeTaken] = useState<string>('2');
  const [customTimeMin, setCustomTimeMin] = useState<string>('');
  const [customTimeSec, setCustomTimeSec] = useState<string>('');
  const [placeOrderValue, setPlaceOrderValue] = useState<string>('');
  const [facility, setFacility] = useState<string>(facilityData[0]?.id);
  const [profilePic, setProfilePic] = useState<string>('');
  const [dob, setDob] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [searchName, setSearchName] = useState<string>('');
  const [selectedUserID, setSelectedUserID] = useState<number>(NOT_SELECTED_USER_ID);

  const [selectFacilityName, setSelectFacilityName] = useState<string>('');
  const [allergies, setAllergies] = useState<string[]>([]);
  const [previousMessages, setPreviousMessages] = useState<ISelectPreviousMsg[]>([]);
  const [placeOrderContentData, setplaceOrderContentData] = useState<IPlaceOrderState>({
    zip_code: '',
    street1: '',
    street2: '',
    city: '',
    state: '',
    country: '',
    name: '',
    logo_url: '',
  });

  const [patientsSearchList, setPatientsSearchList] = useState<IPatientSearchList[]>([])


  // //initial fetching the data to display 
  useEffect(() => {

    const req: IDashboardState = {
      unid, token, client, facility: [0], user_id: logedUserID, general_patient: false
    };
    dispatch(fetchindividualList(req));

  }, []);

  //To reset pagination count
  useEffect(() => {
    setCurrentPage(1)
  }, [messageTab, selectedSingleMsgUser.message_id]);

  useEffect(() => {
    if ((manageToMessage.length === 1 && manageToMessage[0].value === GENERAL) || (loginUserRole === USER_ROLE_PATIENTS || loginUserRole === USER_ROLE_RELATIVES)) {
      setFacility(facilityData[0]?.id);
    }
  }, [facilityData, manageToMessage]);

  //to set required tabs
  useEffect(() => {
    setMessageTab(selectedMessageTab);
    if (selectedMessageTab === MSG_TAB_NEW_MESSAGE) {
      setName("");
      setSearchName("");
      setProfilePic("");
      setDob("");
      setSelectFacilityName("");
      setAllergies([]);
    }
    if (resetMessage) {
      resetMessageValues();
      dispatch(setResetMessageValues(false))
    }
  }, [selectedMessageTab, resetMessage]);


  //To setup the value of siingle selected message
  useEffect(() => {
    if (loading === "FetchSingleDataSuccessfull") {

      setSelectedToMessage([]);
      setManageToMessage([]);
      dispatch(resetPatientSearchList([]));
      setProfilePic(selectedSingleMsgUser.profile_pic);
      setDob(selectedSingleMsgUser.dob);
      setPriority(selectedSingleMsgUser.priority.toString());
      setName(selectedSingleMsgUser.name);
      setSearchName(selectedSingleMsgUser.type === MSG_TYPE_GENERAL ? LBL_GENERAL_PATIENTS : selectedSingleMsgUser.name ? selectedSingleMsgUser.name : '');
      setSelectedUserID(selectedSingleMsgUser.type === MSG_TYPE_GENERAL ? 0 : Number(selectedSingleMsgUser.user_id));
      setSelectFacilityName(selectedSingleMsgUser.facility_name);
      // setLastSelectedPatientEmail(selectedSingleMsgUser.email);
      if (selectedSingleMsgUser.allergy !== "" && selectedSingleMsgUser.allergy !== null) {
        const str = selectedSingleMsgUser.allergy;
        const array = str.split(",");
        setAllergies(array.filter(item => item !== ""));
      }

      handleSetFacility(selectedSingleMsgUser.facility.toString(), selectedSingleMsgUser.type === MSG_TYPE_GENERAL ? true : false);
      setinitialRendering(true);
    }

    if (loading === "fetchIndividualSuccessfully" && initialRendering) {
      setinitialRendering(false);
      if (individualOptions && individualOptions.length > 0) {
        const checkGeneralMsg: boolean = selectedSingleMsgUser.message_result.some(opt => opt.value === GENERAL);

        if (checkGeneralMsg && hasSentGeneralMessagePermission) {
          setSelectedToMessage([...individualOptions, ...moreOptions]);
          setManageToMessage([{ value: GENERAL, label: LBL_GENERAL }]);
        } else {
          const totalList: Option[] = [...individualOptions, ...moreOptions];

          const filteredGroupData = totalList.filter(item =>
            selectedSingleMsgUser.message_result.some(opt => {
              if (opt.value === PATIENTS_RELATIVES) {
                return item.group_name == PATIENTS || item.group_name == RELATIVES
              }
              if (opt.value === CLIENT_ADMIN_ASSOCIATE) {
                return item.group_name == MSG_CLIENT_ADMIN || item.group_name == MSG_CLIENT_ASSOCIATE
              }
              return opt.value === item.group_name

            })
          );

          // const filteredGroupData = totalList.filter(item =>
          //   selectedSingleMsgUser.message_result.some(opt => opt.value === PATIENTS_RELATIVES && (item.group_name == PATIENTS || item.group_name == RELATIVES)) ||
          //   selectedSingleMsgUser.message_result.some(opt => opt.value === CLIENT_ADMIN_ASSOCIATE && (item.group_name == MSG_CLIENT_ADMIN || item.group_name == MSG_CLIENT_ASSOCIATE)) ||
          //   selectedSingleMsgUser.message_result.some(opt => opt.value !== PATIENTS_RELATIVES && opt.value !== CLIENT_ADMIN_ASSOCIATE && opt.value === item.group_name)
          // );

          const filteredIndiv = selectedSingleMsgUser.message_result.map((item) => ({
            label: item.label,
            value: item.value,
            group_name: item.group_name,
            user_id: Number(item.user_id)
          }));

          let mainResult;

          if (loginUserRole === USER_ROLE_PATIENTS || loginUserRole === USER_ROLE_RELATIVES) {
            mainResult = [...filteredGroupData, ...filteredIndiv, ...selectedSingleMsgUser.sender_info].filter(option => option.value !== logedUsername && option.group_name !== MSG_CLIENT_ADMIN && option.value !== CLIENT_ADMIN_ASSOCIATE);

          } else {
            mainResult = [...filteredGroupData, ...filteredIndiv, ...selectedSingleMsgUser.sender_info].filter(option => option.value !== logedUsername);

          }

          setSelectedToMessage(mainResult);

          let showData: Option[] = mainResult;


          if (mainResult.some(obj => obj.value === MSG_PROVIDER)) {
            showData = showData.filter(item => item.group_name !== MSG_PROVIDER);
          }
          if (mainResult.some(obj => obj.value === MSG_NURSING_STAFF)) {
            showData = showData.filter(item => item.group_name !== MSG_NURSING_STAFF);
          }

          if (mainResult.some(obj => obj.value === PATIENTS_RELATIVES)) {
            showData = showData.filter(item => item.group_name !== PATIENTS && item.group_name !== RELATIVES);
          }

          if (mainResult.some(obj => obj.value === CLIENT_ADMIN_ASSOCIATE)) {
            showData = showData.filter(item => item.group_name !== MSG_CLIENT_ADMIN && item.group_name !== MSG_CLIENT_ASSOCIATE);
          }

          setManageToMessage(showData);
        }
      }
    }

  }, [selectedSingleMsgUser, loading]);

  // LOAD THE MESSAGE HISTORY 
  useEffect(() => {
    // const filteredList = selectPreviousMsg.filter((item) => (Number(item.message_type) !== MSG_TYPE_GENERAL || hasViewGeneralMessagePermission) && !(item.message_or_order === MSG_TYPE_ORDER && !hasViewOrderPermission));
    setPreviousMessages(selectPreviousMsg);
  }, [selectPreviousMsg]);



  //to hide to message dropdown while clicking outside of dashboard
  useEffect(() => {
    dispatch(resetPatientSearchList([]));
    const closeSelects = (e: MouseEvent) => {
      if (!e.target || !(e.target instanceof Node)) return;
      if (!dropdownRef.current?.contains(e.target as Node)) {
        setshowToMessage(false);
      }
      if (!dropdownPatRef.current?.contains(e.target as Node)) {
        setshowPatientList(false);
      }
    };

    document.addEventListener('mousedown', closeSelects);

    return () => {
      document.removeEventListener('mousedown', closeSelects);
    };
  }, []);

  //For setting up place_order content
  useEffect(() => {
    setplaceOrderContentData({
      zip_code: placeOrderContent.zip_code,
      street1: placeOrderContent.street1,
      street2: placeOrderContent.street2,
      city: placeOrderContent.city,
      state: placeOrderContent.state,
      country: placeOrderContent.country,
      name: placeOrderContent.name,
      logo_url: placeOrderContent.logo_url,
    })
  }, [placeOrderContent])

  useEffect(() => {
    setPatientsSearchList(patientSearchList)
  }, [patientSearchList])


  //Effect that triggers when clicked on the patient name in the patient list page.
  useEffect(() => {
    if (patientListClickedStatus) {
      onSearchPatientSelect(patientListClickedData);
    }
    dispatch(setPatientListClickedStatus(false));
  }, [patientListClickedStatus])

  //update filter send message list history according to filter in sidebar in sent message list


  useEffect(() => {
    if (messageTab === MSG_TAB_SEND_MESSAGE) {
      const req: IMessageList = {
        unid, token,
        facility: navFacility,
        user_id: logedUserID,
        page: currentPage,
        search: sidebarSearch,
        filter: Number(sidebarfilterOption)
      };
      dispatch(fetchSendDashboardMessage(req));
    }
  }, [sidebarSearch, sidebarfilterOption])



  //FUNCTION TO HANDLE TO MESSAGE
  const handleToMessage = (value: string, label: string) => {

    setSearchToMessage('');
    //check if the value is already selected or not 
    const isSelected = selectedToMessage.some(option => option.value === value);

    //section if selected value is GENERAL
    if (value === GENERAL) {
      if (isSelected) {
        setSelectedToMessage([]);
        setManageToMessage([]);
      } else {
        setSelectedToMessage([...individualOptions, ...moreOptions]);
        setManageToMessage([{ value, label }]);
      }
    }
    //section if selected value is Group
    else if (value === NURSING_STAFF_SPACE || value === PROVIDER || value === PATIENTS_RELATIVES || value === CLIENT_ADMIN_ASSOCIATE) {
      //check if it is already selected or not
      if (isSelected) {

        const filteredData = selectedToMessage.filter(item => {
          if (value === PATIENTS_RELATIVES) {
            return (item.group_name !== value && item.group_name !== PATIENTS && item.group_name !== RELATIVES)
          }
          if (value === CLIENT_ADMIN_ASSOCIATE) {
            return (item.group_name !== value && item.group_name !== MSG_CLIENT_ADMIN && item.group_name !== MSG_CLIENT_ASSOCIATE)
          }
          return item.group_name !== value
        });

        const endData: Option[] = filteredData.filter(obj => {
          if (value === PATIENTS_RELATIVES) {
            return !(obj.value === MSG_GENERAL || obj.value === value || obj.value === PATIENTS || obj.value === RELATIVES)
          }
          if (value === CLIENT_ADMIN_ASSOCIATE) {
            return !(obj.value === MSG_GENERAL || obj.value === value || obj.value === MSG_CLIENT_ADMIN || obj.value === MSG_CLIENT_ASSOCIATE)
          }
          return !(obj.value === MSG_GENERAL || obj.value === value)
        });
        setSelectedToMessage(endData);


        let showData: Option[] = endData;
        // let showData: Option[] = endData.filter(item => item.group_name !== value);


        if (endData.some(obj => obj.value === MSG_PROVIDER)) {
          showData = showData.filter(item => item.group_name !== MSG_PROVIDER);
        }
        if (endData.some(obj => obj.value === MSG_NURSING_STAFF)) {
          showData = showData.filter(item => item.group_name !== MSG_NURSING_STAFF);
        }

        if (endData.some(obj => obj.value === PATIENTS_RELATIVES)) {
          showData = showData.filter(item => item.group_name !== PATIENTS && item.group_name !== RELATIVES);
        }

        if (endData.some(obj => obj.value === CLIENT_ADMIN_ASSOCIATE)) {
          showData = showData.filter(item => item.group_name !== MSG_CLIENT_ADMIN && item.group_name !== MSG_CLIENT_ASSOCIATE);
        }

        setManageToMessage(showData);

      } else {
        const filteredData = individualOptions.filter(item => {
          if (value === PATIENTS_RELATIVES) {
            return (item.group_name === PATIENTS || item.group_name === RELATIVES)
          }
          if (value === CLIENT_ADMIN_ASSOCIATE) {
            return (item.group_name === MSG_CLIENT_ADMIN || item.group_name === MSG_CLIENT_ASSOCIATE)
          }
          return item.group_name === value
        });
        const mergedData: Option[] = selectedToMessage.concat(filteredData);
        const uniqueMergedData: Option[] = mergedData.filter(
          (item, index, self) => self.findIndex(i => i.value === item.value && i.label === item.label) === index
        );
        const endData: Option[] = uniqueMergedData.filter(obj => !(obj.value === MSG_GENERAL));
        setSelectedToMessage([...endData, { value, label }]);



        let showData: Option[] = [...endData, { value, label }];
        // let showData: Option[] = [...endData, { value, label }].filter(item => item.group_name !== value);


        if ([...endData, { value, label }].some(obj => obj.value === MSG_PROVIDER)) {
          showData = showData.filter(item => item.group_name !== MSG_PROVIDER);
        }
        if ([...endData, { value, label }].some(obj => obj.value === MSG_NURSING_STAFF)) {
          showData = showData.filter(item => item.group_name !== MSG_NURSING_STAFF);
        }

        if ([...endData, { value, label }].some(obj => obj.value === PATIENTS_RELATIVES)) {
          showData = showData.filter(item => item.group_name !== PATIENTS && item.group_name !== RELATIVES);
        }

        if ([...endData, { value, label }].some(obj => obj.value === CLIENT_ADMIN_ASSOCIATE)) {
          showData = showData.filter(item => item.group_name !== MSG_CLIENT_ADMIN && item.group_name !== MSG_CLIENT_ASSOCIATE);
        }

        setManageToMessage(showData);
      }
    }
    else {
      if (isSelected) {

        // If it's selected, remove it from the selectedToMessage array
        const containsGeneral = selectedToMessage.some(obj => {
          return obj.value === MSG_GENERAL;
        });
        if (containsGeneral) {
          const filteredData = individualOptions.filter(item => item.value !== value);
          setSelectedToMessage(filteredData);
          setManageToMessage(filteredData);
        } else {

          const containsProvider = selectedToMessage.some(obj => {
            return obj.value === MSG_PROVIDER;
          });
          const NursingStaff = selectedToMessage.some(obj => {
            return obj.value === MSG_NURSING_STAFF;
          });
          const containsPatientOrRelative = selectedToMessage.some(obj => {
            return obj.value === PATIENTS_RELATIVES;
          });
          const containsAssociates = selectedToMessage.some(obj => {
            return obj.value === CLIENT_ADMIN_ASSOCIATE;
          });

          const groupName = selectedToMessage.filter(item => (item.value !== MSG_PROVIDER && item.value !== MSG_NURSING_STAFF && item.value !== PATIENTS_RELATIVES && item.value !== CLIENT_ADMIN_ASSOCIATE)).filter(item => item.value === value)[0].group_name;

          if ((groupName === PROVIDER && containsProvider) || (groupName === NURSING_STAFF_SPACE && NursingStaff) || ((groupName === PATIENTS || groupName === RELATIVES) && containsPatientOrRelative) || ((groupName === MSG_CLIENT_ADMIN || groupName === MSG_CLIENT_ASSOCIATE) && containsAssociates)) {
            return
          }

          setSelectedToMessage(selectedToMessage.filter(option => option.value !== value));

          setManageToMessage(manageToMessage.filter(option => option.value !== value));

        }
      } else {

        // If it's not selected, add it to the selectedToMessage array
        const filteredData = individualOptions.filter(item => item.value === value);
        setSelectedToMessage([...selectedToMessage, ...filteredData]);
        setManageToMessage([...manageToMessage, { value, label }]);
      }
    }
  };


  //function to send message
  const handleSentMessage = () => {

    let receiverdata: {
      INDIVIDUAL?: number[];
      PROVIDER?: number[];
      PATIENTS?: number[];
      [NURSING_STAFF_SPACE]?: number[];
      GENERAL?: number[];
    } = {
      INDIVIDUAL: [],
      PROVIDER: [],
      PATIENTS: [],
      [NURSING_STAFF_SPACE]: [],
      GENERAL: [],
    };

    const containsGeneral = selectedToMessage.some(obj => obj.value === MSG_GENERAL);
    const containsNursing = selectedToMessage.some(obj => obj.value === MSG_NURSING_STAFF);
    const containsProvider = selectedToMessage.some(obj => obj.value === MSG_PROVIDER);
    const containsPatient = selectedToMessage.some(obj => obj.group_name === MSG_PATIENTS || obj.group_name === MSG_RELATIVES || obj.value === PATIENTS_RELATIVES);

    const containsAssociate = selectedToMessage.some(obj => obj.value === CLIENT_ADMIN_ASSOCIATE);


    const filteredData = selectedToMessage.filter(item => !(item.value === MSG_GENERAL || item.value === MSG_NURSING_STAFF || item.value === MSG_PROVIDER || item.group_name === MSG_PATIENTS || item.group_name === MSG_RELATIVES || item.value === PATIENTS_RELATIVES || item.value === MSG_CLIENT_ADMIN || item.value === MSG_CLIENT_ASSOCIATE || item.value === CLIENT_ADMIN_ASSOCIATE));

    if (containsGeneral) {
      receiverdata = { GENERAL: individualOptions.map(item => item.user_id ? item.user_id : 0) };
    } else if (containsNursing || containsProvider || containsPatient || containsAssociate) {
      const receiver: any = {};
      const selGroups: any = [];
      let selIndividual: any = [];

      if (containsNursing) {
        receiver[NURSING_STAFF_SPACE] = [];
        selGroups.push(NURSING_STAFF_SPACE);
      }

      if (containsProvider) {
        receiver.PROVIDER = [];
        selGroups.push(PROVIDER);
      }
      if (containsPatient) {
        if (selectedUserID === 0) {
          const uniqueUserIds = new Set<number>();
          // receiver.GENERAL_PATIENTS = selectedToMessage.filter(item => ((item.group_name === MSG_PATIENTS || item.group_name === MSG_RELATIVES) && (item.value !== MSG_GENERAL && item.value !== MSG_NURSING_STAFF && item.value !== MSG_PROVIDER))).map(item => item.user_id ? Number(item.user_id) : 0);
          receiver.GENERAL_PATIENTS = selectedToMessage.filter(item => ((item.group_name === MSG_PATIENTS || item.group_name === MSG_RELATIVES) && (item.value !== MSG_GENERAL && item.value !== MSG_NURSING_STAFF && item.value !== MSG_PROVIDER))).map(item => {
            const userId = item.user_id ? Number(item.user_id) : 0;
            if (userId !== 0 && !uniqueUserIds.has(userId)) {
              uniqueUserIds.add(userId);
              return userId;
            }
            return 0; // Return 0 for invalid user IDs
          }).filter(userId => userId !== 0);
          selGroups.push(MSG_PATIENTS, MSG_RELATIVES);
        } else {
          receiver.PATIENTS = selectedToMessage.filter(item => ((item.group_name === MSG_PATIENTS || item.group_name === MSG_RELATIVES) && (item.value !== MSG_GENERAL && item.value !== MSG_NURSING_STAFF && item.value !== MSG_PROVIDER))).map(item => item.user_id ? Number(item.user_id) : 0);
          selGroups.push(MSG_PATIENTS, MSG_RELATIVES);
        }

      }

      if (containsAssociate) {
        receiver[MSG_CLIENT_ASSOCIATE] = [];
        selGroups.push(MSG_CLIENT_ADMIN, MSG_CLIENT_ASSOCIATE);
      }

      selIndividual = selectedToMessage.filter(item => !(item.value === MSG_GENERAL || item.value === MSG_NURSING_STAFF || item.value === MSG_PROVIDER || item.value === PATIENTS_RELATIVES || item.value === CLIENT_ADMIN_ASSOCIATE)).filter(item => !selGroups.includes(item.group_name)).map(item => item.user_id ? Number(item.user_id) : 0).filter(item => item !== 0);


      if (selIndividual.length > 0) {

        receiverdata = {
          ...receiver,
          INDIVIDUAL: selIndividual,
        }
      } else {
        receiverdata = {
          ...receiver,
        }
      }

    } else {
      receiverdata = {
        INDIVIDUAL: filteredData.map(item => item.user_id ? Number(item.user_id) : 0).filter(item => item !== 0),
      }
    }

    const req: ISentMessage = {
      token,
      unid,
      sender: logedUserID,
      receivers: receiverdata,
      message_or_order: messageType,
      content: messageType === MSG_TYPE_ORDER ? placeOrderValue : value,
      facility: Number(facility),
      priority: Number(priority),
      related_patient: (loginUserRole === USER_ROLE_PATIENTS || loginUserRole === USER_ROLE_RELATIVES) ? [logedUserID] : [Number(selectedUserID)],
      time_taken_min: ((loginUserRole === USER_ROLE_PROVIDER || loginUserRole === USER_ROLE_CLIENT_ASSOCIATE) && selectedUserID !== 0
        // || loginUserRole === USER_ROLE_CLIENT_ADMIN || loginUserRole === USER_ROLE_CLIENT_ASSOCIATE
      ) ? timeTaken === minMinutes ? Number(customTimeMin) : Number(timeTaken) : 0,
      time_taken_sec: ((loginUserRole === USER_ROLE_PROVIDER || loginUserRole === USER_ROLE_CLIENT_ASSOCIATE) && selectedUserID !== 0
        // || loginUserRole === USER_ROLE_CLIENT_ADMIN || loginUserRole === USER_ROLE_CLIENT_ASSOCIATE
      ) ? timeTaken === minMinutes ? Number(customTimeSec) : 0 : 0,
    };
    const convertedValue = convertHtmlToString(value)?.trim();

    const isValidMessageType = messageType === MSG_TYPE_ORDER
      ? placeOrderValue.length > 0 : (value.length > 0 ? (convertedValue?.length ?? 0) > 0 : false);



    if (isValidMessageType) {
      if (selectedToMessage.length > 0) {
        if ((loginUserRole === USER_ROLE_PATIENTS || loginUserRole === USER_ROLE_RELATIVES) ? true : selectedUserID !== NOT_SELECTED_USER_ID) {
          // if ((loginUserRole === USER_ROLE_PROVIDER || loginUserRole === USER_ROLE_CLIENT_ASSOCIATE) ? timeTaken === minMinutes ? (Number(customTimeMin) !== 0 || Number(customTimeSec) !== 0) : true : true) {
          dispatch(onHandleSentMessage(req));
          setmessageValidation(false);
          setmessageToValidation(false);
          setSelectPatientValidation(false);
          //   setTimeTakenValidation(false);
          // } else {
          //   setTimeTakenValidation(true);
          // }

        } else {
          setSelectPatientValidation(true);
        }
      } else {
        setmessageToValidation(true);
      }
    } else {
      setmessageValidation(true);
      setValue('');
    }

    setSelMessageToType("");
  }

  const resetMessageValues = () => {
    setSelectedToMessage([]);
    setManageToMessage([]);
    setValue('');
    setPlaceOrderValue('');
    setTimeTaken('2');
    setCustomTimeMin('');
    setCustomTimeSec('');
    setPriority('1');
    setSelectedUserID(NOT_SELECTED_USER_ID);
    setmessageValidation(false);
    setmessageToValidation(false);
    setSelectPatientValidation(false);
    // setTimeTakenValidation(false);
  }

  const handleClickOnToMessage = () => {
    setshowToMessage(true);
    if (inputRef.current) {
      inputRef.current.focus(); // Focus on the input element
    }
  };
  // pagination    
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    if (messageTab === MSG_TAB_SEND_MESSAGE) {
      const req: IMessageList = {
        unid, token,
        facility: navFacility,
        user_id: logedUserID,
        page: page,
        search: sidebarSearch,
        filter: Number(sidebarfilterOption)
      };
      dispatch(fetchSendDashboardMessage(req));

    } else {      
      const req: ISingleMessage = {
        unid, token,
        facility: selectedSingleMsgUser.facility,
        sender_id: Number(selectedSingleMsgUser.sender_id),
        type: selectedSingleMsgUser.type,
        group_name: selectedSingleMsgUser.group_name,
        user_id: logedUserID,
        message_id: selectedSingleMsgUser.message_id,
        page: page
      };
      dispatch(handleSingleMessage(req));
    }
  };

  //Placeorder
  const handlePlaceOrder = () => {
    setMessageType(MSG_TYPE_ORDER);
    dispatch(setPlaceOrderContent({ token, unid, client_id: client, facility_id: Number(facility) }))
  };

  //Search patient by name
  const onchangePatientSearch = (sValue: string) => {

    setSearchName(sValue);
    setPatientsSearchList([]);
    if (sValue.trim().length > 1) {
      const searchData: IPatientSearchByName = {
        unid: unid,
        token: token,
        client: client,
        user_id: logedUserID,
        facility: navFacility,
        search: sValue.replace(",", "").trim()
      }
      dispatch(patientSearchByName(searchData));
    }
    else if (sValue.trim() === '') {
      setMessageType(MSG_TYPE_MESSAGE)
      setName('');
      setSearchName('');
      setProfilePic("");
      setDob('');
      setSelectFacilityName('');
      setAllergies([]);

      setManageToMessage([]);
      setSelectedToMessage([]);
      setSelectedUserID(NOT_SELECTED_USER_ID);
      setPreviousMessages([]);
      handleSetFacility('0', false);

      setmessageToValidation(false);
      setSelectPatientValidation(false);
      // setTimeTakenValidation(false);
    }
  }

  const handleSetFacility = (value: string, general_patient: boolean) => {
    setFacility(value);
    const req: IDashboardState = {
      unid, token, client, facility: [Number(value)], user_id: logedUserID, general_patient: general_patient
    };
    dispatch(fetchindividualList(req));
  }

  const onSearchPatientSelect = (objData: IPatientSearchList) => {
    setshowPatientList(false);
    setName(objData.name);
    setSearchName(objData.name);
    setProfilePic(objData.profile_pic);
    setDob(objData.patient_dob);
    setSelectFacilityName(objData?.facility_name ? objData.facility_name : "");
    setSelectedUserID(objData.user_id);
    if (objData.user_id !== 0) {

      setAllergies(objData.allergies.split(',').filter(item => item !== ""));

//function when patient is searched above co-responding patient or family user loaded on message to  // -start

      // if (objData.email && objData.email.length > 0) {
      //   setManageToMessage([{ "value": objData.email, "label": objData.name }]);
      //   setSelectedToMessage([{ "value": objData.email, "label": objData.name, "group_name": PATIENTS, user_id: objData.user_id }]);
      // }
      if (objData.email && objData.email.length > 0) {
        setSelectedPatientSearchDetail([{ "value": objData.email, "label": objData.name, "group_name": PATIENTS, user_id: objData.user_id }]);
      }
      
//function when patient is searched above co-responding patient or family user loaded on message to  // -stop



      // setLastSelectedPatientEmail(objData.email);
      handleSetFacility(objData?.facility_id ? objData.facility_id.toString() : '0', false);
      dispatch(resetPatientSearchList([]));

      // LOAD PATIENT MESSAGE HISTORY 
      const reqTwo: IPatientMessageHistory = {
        token: token,
        unid: unid,
        facility: Number(objData.facility_id),
        type: MSG_TYPE_MESSAGE,
        sender_id: Number(objData.user_id),
        group_name: "",
        search: "",
        page: 1,
      }
      dispatch(fetchPatientMessageHistory(reqTwo));
    } else {
      handleSetFacility(facilityData[0]?.id, true);
    }
  }



  //Function to select color  
  const userColor = (parent: any) => {

    switch (parent) {
      case MSG_CLIENT_ADMIN:
        return '#ED9348';
      case MSG_CLIENT_ASSOCIATE:
        return '#F2C94C ';
      case MSG_PATIENTS:
        return '#27AE60 ';
      case MSG_RELATIVES:
        return '#27AE60 ';
      case MSG_NURSING_STAFF:
        return '#2196F3 ';
      case MSG_PROVIDER:
        return '#BB6BD9 ';
      case MSG_GENERAL:
        return '#E0E0E0 ';
      default:
        return 'white'; // Set a default background color class
    }

  }

  return (
    <>

      {(messageTab === MSG_TAB_NO_MESSAGE && loginUserRole !== USER_ROLE_PATIENTS && loginUserRole !== USER_ROLE_RELATIVES) ?
        <div className='w-full flex flex-col gap-6 items-center  mt-36 '>
          <img src={patientImg} alt="" loading='lazy' decoding='async' />
          <h2 className='text-2xl font-medium text-[var(--color-424242)] tracking-[0.05px]'>No patient selected</h2>
        </div> :
        <>
          <div className={'w-full ' + `${previousMessages.length > 0 ? "  bg-[var(--color-F5F5F5)] " : "  bg-[var(--color-white)] "}`}>

            <div className='w-full bg-[var(--color-white)] px-8 border-b border-[var(--color-EEE)]'>
              {/* PATEINT DETAILS SHOW ONLY IF IT IS NOT GENERAL */}
              {/* {manageToMessage.length === 1 && manageToMessage[0].value === GENERAL ? <> </> : */}
              <>
                {((messageTab === MSG_TAB_SEL_MESSAGE || messageTab === MSG_TAB_NEW_MESSAGE) && (loginUserRole !== USER_ROLE_PATIENTS && loginUserRole !== USER_ROLE_RELATIVES)) &&
                  <div className="flex justify-between items-center pb-[10px] pt-[29px] border-b border-[var(--color-EEE)]">
                    <div className="flex items-center gap-4">
                      <Avatar className='h-[50px] w-[50px]' alt={name} src={profilePic} />

                      <div>
                        <div className='common-dropdown '>
                          {/* <div className='extra-space '></div> */}
                          <div className='tracking-[0.05px] text-[var(--color-212121)] w-[250px] ' >
                            <TextField
                              type="search"
                              placeholder="Search by patient name"
                              value={searchName}
                              onChange={(e) => onchangePatientSearch(e.target.value)}
                              onClick={() => setshowPatientList(true)}
                              className={"focus:outline-none w-full text-base font-medium text-[var(--color-424242)] px-2   h-[45px] " + `${(selectPatientValidation && selectedUserID === NOT_SELECTED_USER_ID) ? " border-[var(--color-danger)] " : " border-[var(--color-EEE)] "}`}
                            />
                          </div>

                          {patientsSearchList && patientsSearchList.length > 0 && showPatientList && <div className={`${(patientsSearchList.length > 0 && showPatientList) ? 'block dropdown-list rounded-lg min-w-[250px] mt-3 ' : ' hidden'}  `} ref={dropdownPatRef}>
                            <ul className="custom-scrollBar max-h-[250px]" >
                              {patientsSearchList.map(option => (
                                <li
                                  key={option.id}
                                  className="text-[var(--color-757575)] tracking-[0.05px] hover:text-[var(--color-0048D4)] my-[8px] me-6"
                                >
                                  <label className='flex items-center gap-2 justify-start cursor-pointer text-base font-medium' onClick={() => onSearchPatientSelect(option)}>
                                    {option.name}
                                  </label>
                                </li>
                              ))}
                            </ul>
                          </div>}
                        </div>

                        {dob && <p className='text-sm font-medium mt-1 px-2'><span className='text-[var(--color-9E9E9E)]'>DOB : </span><span className='text-[var(--color-212121)]'>{dob ? dayjs(dob).format(`MM/DD/YYYY`) : <></>}</span></p>}
                      </div>
                    </div>
                    <div>
                      <div className="flex gap-[11px] items-center justify-end mb-2">
                        {selectFacilityName && selectFacilityName.trim().length > 0 && <img src={facilityIcon} alt="facilityIcon" />}
                        <span className='text-sm font-medium text-[var(--color-212121)]'>{selectFacilityName}</span>
                      </div>
                      {(allergies?.length > 0 && allergies !== null) && <div className="flex gap-2 items-center">
                        <img src={allergicIcon} alt="facilityIcon" loading='lazy' decoding='async' />
                        {allergies?.map(item => <span className='allergic-item'>{item}</span>)}
                      </div>}
                    </div>
                  </div>}
              </>
              {/* } */}

              {(messageTab !== MSG_TAB_SEND_MESSAGE && hasSentMessagePermission) && <>
                <div>
                  <h3 className='text-xs font-medium pt-5 mb-4'>Message to:</h3>
                  <div className="flex gap-4">


                    <div className={`common-dropdown w-full`} onClick={handleClickOnToMessage}>
                      <div className='extra-space '></div>
                      <div className={` ${(messageToValidation && manageToMessage.length <= 0) ? " border-[var(--color-danger)] " : " border-[var(--color-EEE)] "} rounded p-2 common-input  flex flex-wrap gap-[9px]`}>

                        {
                          manageToMessage.length > 0 && (
                            manageToMessage.map(item => (
                              <div key={item.value} className='bg-[var(--color-EEEEEE)] rounded flex items-center gap-[10px] w-fit px-2 py-1 z-[1]'>
                                <p className='text-xs font-medium text-[var(--color-424242)] w-full'>{item.label}</p> <img src={closeIcon} alt="" loading='lazy' decoding='async' className='cursor-pointer' onClick={() => handleToMessage(item.value, item.label)} />
                              </div>
                            )))}


                        <input type="text" name="" id="" className='border-none focus:outline-none' value={searchToMessage} onChange={(e) => setSearchToMessage(e.target.value)} ref={inputRef} />

                      </div>
                      {((individualOptions.filter(
                        (option) =>
                          option.label.toLowerCase().includes(searchToMessage.toLowerCase())
                      ).length > 0) || (moreOptions.filter(option =>
                        option.label.toLowerCase().includes(searchToMessage.toLowerCase())
                      ).length > 0) && moreOptions.filter(option =>
                        option.label.toLowerCase().includes(searchToMessage.toLowerCase())
                      ).map(option => (
                        (option.label === LBL_NURSING_STAFF && individualOptions.some(item => item.group_name === MSG_NURSING_STAFF)) ||
                        (option.label === LBL_PROVIDERS && individualOptions.some(item => item.group_name === MSG_PROVIDER)) ||
                        (option.label === LBL_ASSOCIATES && individualOptions.some(item => (item.group_name === MSG_CLIENT_ADMIN || item.group_name === MSG_CLIENT_ASSOCIATE))) ||
                        (option.label === LBL_PATIENTS && individualOptions.some(item => (item.group_name === MSG_PATIENTS || item.group_name === MSG_RELATIVES))) ||
                        (option.label === LBL_GENERAL && hasSentGeneralMessagePermission && individualOptions.length > 0))).some(item => item)) &&
                        <div className={`${(searchToMessage.length > 0 && showToMessage) ? 'block py-6 pl-6 dropdown-list w-[288px]  top-full left-0 ' : 'hidden'}  `} ref={dropdownRef}>

                          {moreOptions.filter(option =>
                            option.label.toLowerCase().includes(searchToMessage.toLowerCase())
                          ).map(option => (
                            (option.label === LBL_NURSING_STAFF && individualOptions.some(item => item.group_name === MSG_NURSING_STAFF)) ||
                            (option.label === LBL_PROVIDERS && individualOptions.some(item => item.group_name === MSG_PROVIDER)) ||
                            (option.label === LBL_ASSOCIATES && individualOptions.some(item => (item.group_name === MSG_CLIENT_ADMIN || item.group_name === MSG_CLIENT_ASSOCIATE))) ||
                            (option.label === LBL_PATIENTS && individualOptions.some(item => (item.group_name === MSG_PATIENTS || item.group_name === MSG_RELATIVES))) ||
                            (option.label === LBL_GENERAL && hasSentGeneralMessagePermission))).some(item => item) && <>
                              <span className='text-sm font-medium text-[var(--color-9E9E9E)] '>Groups</span>
                              <ul className="custom-scrollBar max-h-[250px] flex flex-col gap-1 mt-2">
                                {moreOptions.filter(option =>
                                  option.label.toLowerCase().includes(searchToMessage.toLowerCase())
                                ).map(option => (
                                  (option.label === LBL_NURSING_STAFF && individualOptions.some(item => item.group_name === MSG_NURSING_STAFF)) ||
                                    (option.label === LBL_PROVIDERS && individualOptions.some(item => item.group_name === MSG_PROVIDER)) ||
                                    (option.label === LBL_ASSOCIATES && individualOptions.some(item => (item.group_name === MSG_CLIENT_ADMIN || item.group_name === MSG_CLIENT_ASSOCIATE))) ||
                                    (option.label === LBL_PATIENTS && individualOptions.some(item => (item.group_name === MSG_PATIENTS || item.group_name === MSG_RELATIVES))) ||
                                    (option.label === LBL_GENERAL && hasSentGeneralMessagePermission && individualOptions.length > 0) ?
                                    <li
                                      key={option.value}
                                      className={`${selectedToMessage.some(item => item.value === option.value) ? " bg-[var(--color-0048d41a)] text-[var(--color-0048D4)] font-medium" : " bg-[var(--color-FAFAFA)] text-[var(--color-757575)]"} dropdown-btn w-fit`}
                                      onClick={() => handleToMessage(option.value, option.label)}
                                    >
                                      {option.label}
                                    </li> : <></>
                                ))}
                              </ul>
                              {(individualOptions.filter(
                                (option) =>
                                  option.label.toLowerCase().includes(searchToMessage.toLowerCase())
                              ).length > 0) && <hr className='border-[var(--color-EEE)] my-2 mr-6' />}
                            </>}
                          {individualOptions.filter(
                            (option) =>
                              option.label.toLowerCase().includes(searchToMessage.toLowerCase())
                          ).length > 0 &&
                            <>
                              <span className='text-sm font-medium text-[var(--color-9E9E9E)] '>Individual</span>
                              <ul className="custom-scrollBar max-h-[250px] flex flex-col  gap-1 mt-2">
                                {individualOptions.filter(
                                  (option) =>
                                    option.label.toLowerCase().includes(searchToMessage.toLowerCase())
                                ).map(option => (
                                  <li
                                    key={option.value}
                                    className={`${selectedToMessage.some(item => item.value === option.value) ? " bg-[var(--color-0048d41a)] text-[var(--color-0048D4)] font-medium" : " bg-[var(--color-FAFAFA)] text-[var(--color-757575)]"} dropdown-btn w-fit `}
                                    onClick={() => handleToMessage(option.value, option.label)}
                                  >
                                    {option.label}
                                  </li>
                                ))}
                              </ul>
                            </>}

                        </div>}
                    </div>

                    {/* {manageToMessage.length === 1 && manageToMessage[0].value === GENERAL && <div> */}
                    {selectedUserID === 0 && <div>
                      <div className="flex items-center gap-3" >
                        <div className='text-sm tracking-[0.05px] text-[var(--color-424242)] pt-1'>Facility</div>
                        <div>
                          <SelectField
                            className={`${(facilityData && facilityData.length > 0) ? ' border-[var(--color-EEE)] ' : ' border-[var(--color-danger)] '} pr-11 py-[12px] rounded  text-[13px] min-w-[180px]`}
                            required={true}
                            onChange={(e) => {
                              handleSetFacility(e.target.value, true);
                              setSelectedToMessage([]);
                              setManageToMessage([]);
                              setmessageToValidation(false);
                            }}
                            value={facility}
                            options={facilityData}
                            selLength={15}
                          />
                        </div>
                      </div>
                    </div>}

                    <div className={`common-dropdown rounded bg-[var(--color-253179)] px-3 max-h-12 w-11`}>
                      <div className={`${extraSpace && "h-[460px] min-w-[288px] "} extra-space z-[1] right-0 w-[65px] `}></div>
                      <div className='w-11'>
                        <img src={personAddIcon} alt="" loading='lazy' decoding='async' />
                      </div>

                      <div className={`py-6 pl-6 dropdown-list min-w-[288px]  top-[50px] right-0`}>
                        <span className='text-sm font-medium text-[var(--color-9E9E9E)] '>Groups</span>
                        <ul className="custom-scrollBar max-h-[250px] flex flex-col  gap-1 mt-2">
                          {moreOptions.map(option => (
                            (option.label === LBL_NURSING_STAFF && individualOptions.some(item => item.group_name === MSG_NURSING_STAFF)) ||
                              (option.label === LBL_PROVIDERS && individualOptions.some(item => item.group_name === MSG_PROVIDER)) ||
                              (option.label === LBL_ASSOCIATES && individualOptions.some(item => (item.group_name === MSG_CLIENT_ADMIN || item.group_name === MSG_CLIENT_ASSOCIATE))) ||
                              (option.label === LBL_PATIENTS && individualOptions.some(item => (item.group_name === MSG_PATIENTS || item.group_name === MSG_RELATIVES))) ||
                              (option.label === LBL_GENERAL && hasSentGeneralMessagePermission && individualOptions.length > 0) ?
                              <li
                                key={option.value}
                                className={`${selectedToMessage.some(item => item.value === option.value) ? " bg-[var(--color-0048d41a)] text-[var(--color-0048D4)] font-medium" : " bg-[var(--color-FAFAFA)] text-[var(--color-757575)]"} dropdown-btn w-fit`}
                                onClick={() => handleToMessage(option.value, option.label)}
                              >
                                {option.label}
                              </li> : <></>
                          ))}
                        </ul>
                        <hr className='border-[var(--color-EEE)] my-2 mr-6 -ml-[7px]' />
                        {/* <span className='text-sm font-medium text-[var(--color-9E9E9E)] '>Individual</span> */}
                        <div className='mr-6 -ml-[7px] '>
                          <SelectField
                            className={`pr-10 py-[6px] rounded border-[var(--color-EEE)] mr-6 text-sm font-medium text-[var(--color-9E9E9E)]`}
                            onChange={(e) => {
                              setSelMessageToType(e.target.value);
                              setTimeout(() => {
                                setExtraSpace(false);
                              }, 2000);
                            }}
                            onClick={() => setExtraSpace(!extraSpace)}
                            value={selMessageToType}
                            options={[{ name: 'Select Group', id: '' }, ...filteredOptions]}
                          />
                        </div>
                        {selMessageToType && selMessageToType.trim().length > 0 && <ul className="custom-scrollBar max-h-[250px] flex flex-col  gap-1 mt-2">
                          {individualOptions.filter(selOpt => {
                            if (selMessageToType === PATIENTS_RELATIVES) {
                              return selOpt.group_name === MSG_PATIENTS || selOpt.group_name === MSG_RELATIVES
                            } else if (selMessageToType === CLIENT_ADMIN_ASSOCIATE) {
                              return selOpt.group_name === MSG_CLIENT_ADMIN || selOpt.group_name === MSG_CLIENT_ASSOCIATE
                            }

                            return selOpt.group_name === selMessageToType

                          }).map(option => (
                            <li
                              key={option.value}
                              className={`${selectedToMessage.some(item => item.value === option.value) ? " bg-[var(--color-0048d41a)] text-[var(--color-0048D4)] font-medium" : " bg-[var(--color-FAFAFA)] text-[var(--color-757575)]"} dropdown-btn w-fit `}
                              onClick={() => handleToMessage(option.value, option.label)}
                            >
                              {option.label}
                            </li>
                          ))}
                        </ul>}
                      </div>
                    </div>

                  </div>
                </div>

                <div>
                  <div className="w-full flex justify-between items-center border-b border-[var(--color-EEE)] mt-[30px] mb-[27px]">
                    <div className="flex gap-6">
                      <div className={`flex gap-[10px] pb-[12px] text-sm cursor-pointer text-[var(--color-424242)] ${messageType === MSG_TYPE_MESSAGE && "active-msg"}`} onClick={() => setMessageType(MSG_TYPE_MESSAGE)}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g id="Component 19">
                            <path id="Vector" d="M2.28845 16.5V3.5L17.7116 10L2.28845 16.5ZM3.28845 15L15.1385 10L3.28845 5V8.88463L8.1346 10L3.28845 11.1154V15Z" fill={`${messageType === MSG_TYPE_MESSAGE ? "var(--color-0048D4)" : "var(--color-424242)"}`} />
                          </g>
                        </svg> <span>{messageTab === MSG_TAB_NEW_MESSAGE ? 'New Message' : 'Reply Message'}</span>
                      </div>
                      {((loginUserRole === USER_ROLE_PROVIDER
                        // || loginUserRole === USER_ROLE_CLIENT_ADMIN
                      ) && hasSentOrderPermission && (!notAllowedUserIDs.includes(selectedUserID))) && <div className={`flex gap-[10px] pb-[12px] text-sm cursor-pointer text-[var(--color-424242)] ${messageType === MSG_TYPE_ORDER && "active-msg"}`} onClick={() => handlePlaceOrder()}>
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="Component 19">
                              <path id="Vector" d="M7.20835 9.79171V8.70842H12.7916V9.79171H7.20835ZM7.20835 6.79171V5.70841H12.7916V6.79171H7.20835ZM5.5 12.2084H11.25C11.5788 12.2084 11.8826 12.2777 12.1616 12.4162C12.4406 12.5548 12.6779 12.7505 12.8734 13.0033L14.5 15.1283V3.7565C14.5 3.68171 14.476 3.62027 14.4279 3.57219C14.3798 3.5241 14.3184 3.50006 14.2436 3.50006H5.75644C5.68165 3.50006 5.62021 3.5241 5.57212 3.57219C5.52404 3.62027 5.5 3.68171 5.5 3.7565V12.2084ZM5.75644 16.5001H14.1811L12.0233 13.6689C11.9266 13.5414 11.8093 13.4466 11.6715 13.3847C11.5337 13.3227 11.3932 13.2917 11.25 13.2917H5.5V16.2436C5.5 16.3184 5.52404 16.3799 5.57212 16.4279C5.62021 16.476 5.68165 16.5001 5.75644 16.5001ZM14.2427 17.5834H5.75052C5.37519 17.5834 5.05905 17.4539 4.8021 17.1948C4.54516 16.9358 4.41669 16.6187 4.41669 16.2436V3.7565C4.41669 3.38139 4.54628 3.06432 4.80548 2.80529C5.06467 2.54626 5.38194 2.41675 5.75727 2.41675H14.2495C14.6248 2.41675 14.941 2.54626 15.1979 2.80529C15.4548 3.06432 15.5833 3.38139 15.5833 3.7565V16.2436C15.5833 16.6187 15.4537 16.9358 15.1945 17.1948C14.9353 17.4539 14.6181 17.5834 14.2427 17.5834Z" fill={`${messageType === MSG_TYPE_ORDER ? "var(--color-0048D4)" : "var(--color-424242)"}`} />
                            </g>
                          </svg> <span>Place Order</span>
                        </div>}
                    </div>

                  </div>
                  <div>
                    {messageType === MSG_TYPE_MESSAGE &&
                      <ReactQuill theme="snow" value={value} onChange={setValue} modules={{ toolbar: false }} className={` h-[226px]  ${(messageValidation && value.length <= 0) && " validation-ql "} `} />}

                    {(messageType === MSG_TYPE_ORDER && (loginUserRole === USER_ROLE_PROVIDER
                      // || loginUserRole === USER_ROLE_CLIENT_ADMIN
                    ) && (!notAllowedUserIDs.includes(selectedUserID)) && hasSentOrderPermission) &&
                      <div className="flex flex-col border border-[var(--color-EEE)]">
                        <div className="flex justify-between items-center px-[23px] py-4 border-b border-[var(--color-EEE)]">
                          <div className="flex text-sm font-medium text-[var(--color-212121)] items-center gap-[11px]">
                            {placeOrderContentData.logo_url !== "" && <img src={placeOrderContentData.logo_url} alt="" className=' h-auto max-h-[42px] w-auto' loading='lazy' decoding='async' />}
                            <div className="flex flex-col"><span>{placeOrderContentData.name}</span></div>
                          </div>
                          <div className="flex flex-col text-xs text-[var(--color-757575)] items-end">
                            <span>
                              {placeOrderContentData.street1} {placeOrderContentData.street2}</span>
                            <span>{placeOrderContentData.city} {placeOrderContentData.state} {placeOrderContentData.zip_code}</span>
                          </div>
                        </div>
                        <div>
                          <textarea name="" id="" value={placeOrderValue} onChange={(e) => setPlaceOrderValue(e.target.value)} cols={30} rows={7} className='w-full focus:outline-none resize-none h-36 p-4 ' />
                        </div>
                      </div>
                    }
                  </div>
                </div>


                <div className="flex w-full pt-8 pb-[38px]">

                  <label htmlFor="" className='flex gap-3 items-center'><span className='text-[13px] tracking-[0.05px] text-[var(--color-757575)]'>Priority</span>
                    <SelectField
                      className=' py-[6px] rounded border-[var(--color-BDBDBD)] text-[13px] min-w-[173px]'
                      options={[{ name: 'Normal', id: '1' }, { name: 'Urgent', id: '2' }]} value={priority} onChange={(e) => setPriority(e.target.value)}
                    />
                  </label>
                  {(((loginUserRole === USER_ROLE_PROVIDER || loginUserRole === USER_ROLE_CLIENT_ASSOCIATE) && selectedUserID !== 0)
                    // || loginUserRole === USER_ROLE_CLIENT_ADMIN || loginUserRole === USER_ROLE_CLIENT_ASSOCIATE
                  ) && <>
                      {/* {(loginUserRole !== USER_ROLE_NURSING_STAFF && (loginUserRole !== USER_ROLE_PATIENTS && loginUserRole !== USER_ROLE_RELATIVES)) && <> */}
                      <label htmlFor="" className='flex gap-3 items-center ml-[60px]'><span className='text-[13px] tracking-[0.05px] text-[var(--color-757575)]'>Time&nbsp;Taken</span>
                        <SelectField
                          className=' py-[6px] rounded border-[var(--color-BDBDBD)] text-[13px] min-w-[173px]'
                          value={timeTaken}
                          onChange={(e) => setTimeTaken(e.target.value)}
                          options={[{ name: '2 min', id: '2' }, { name: '4 min', id: '4' }, { name: '8 min', id: '8' }, { name: '0 min', id: '0' }, { name: 'Custom time', id: minMinutes }]}
                        />
                      </label>
                    </>}

                  {timeTaken === minMinutes &&
                    <div className='flex items-center justify-center ms-5'>
                      {/* <div className={`flex items-center justify-center ps-1 rounded border ${timeTakenValidation ? ' border-[var(--color-danger)]' : ' border-[var(--color-BDBDBD)]'}`}> */}
                      <div className={`flex items-center justify-center ps-1 rounded border border-[var(--color-BDBDBD)] `}>
                        <input
                          required={timeTaken === minMinutes}
                          className=' hide-number-arrow ps-1 pe-0 py-[6px] tracking-wider rounded-lg focus:outline-none text-[13px] w-fit max-w-[33px] border-[var(--color-white)] '
                          value={customTimeMin}
                          type='number'
                          min={0}
                          max={99}
                          maxLength={2}
                          onChange={(e) => {
                            setCustomTimeMin(filterDigitsOnly(e.target.value.slice(0, 2)));
                            if (e.target.value.length >= 2 && secondFieldRef.current) {
                              // If the length of input is 2 or more, focus on the second field
                              secondFieldRef.current.focus();
                            }
                            // setTimeTakenValidation(false);
                          }}
                          placeholder='mm' />
                        <span className='pb-[2px]'>:</span>
                        <input
                          required={timeTaken === minMinutes && customTimeSec.length === 0}
                          ref={secondFieldRef}
                          className=' hide-number-arrow px-[6px] tracking-wider  py-[6px] rounded-lg focus:outline-none text-[13px] w-fit max-w-[33px] border-[var(--color-white)]  '
                          value={customTimeSec}
                          type='text'
                          maxLength={Number(customTimeSec) >= 6 ? 1 : 2}
                          onChange={(e) => {
                            if (customTimeSec.length < 1 && Number(e.target.value) >= 6) {
                              setCustomTimeSec(`0${e.target.value}`)
                            } else {
                              setCustomTimeSec(filterDigitsOnly((e.target.value).slice(0, 2)))
                            }

                            // setTimeTakenValidation(false);
                          }}
                          placeholder='ss'
                        />
                      </div>
                    </div>
                  }
                  <div className="flex items-center justify-end w-full">
                    <ButtonType buttonLabel='Send' onClick={() => handleSentMessage()} type='webCardbtn' className=' py-3 px-12 rounded-lg text-base font-medium' />

                  </div>
                </div>
              </>}
            </div>


            {((messageTab !== MSG_TAB_NO_MESSAGE && messageTab !== MSG_TAB_NEW_MESSAGE)) &&
              previousMessages.length > 0 ?
              <div className='w-full p-8'>
                <h3 className='text-base font-medium text-[var(--color-424242)] mb-4'>{(messageTab === MSG_TAB_SEL_MESSAGE) ? <>Previous Messages <span className='text-[var(--color-9E9E9E)] ml-2'>({paginationTotalCount})</span></> : <>Sent Messages</>}</h3>

                <div className='flex flex-col gap-3'>

                  {previousMessages.map(item => <>
                    <div className='pt-3 pb-6 px-6 bg-[var(--color-white)]' key={item.id}>
                      <div className="flex items-center justify-between border-b pb-3">
                        <div className="flex gap-3 items-center">
                          <Avatar className='h-9 w-9' alt={item.sender_name} src={messageTab === MSG_TAB_SEL_MESSAGE ? item.profile_pic : logedUserProfilePic} sx={{ border: `3px solid ${userColor(item.sender_group)}` }} />
                          <span className='text-sm font-medium text-[var(--color-424242)]'>{item.sender_name}</span>
                        </div>
                        <div className="flex gap-6 items-center">
                          <div className='flex flex-col gap-1 items-start w-fit text-[11px] font-medium text-[var(--color-212121)]'>
                            <span className=' flex items-center gap-1'>
                              <img src={calender} alt="calender" loading='lazy' decoding='async' className='h-5 w-auto ' />
                              {dayjs(item.created_on).format('MMM D, YYYY, hh:mm A')} {dayjs().diff(item.created_on, 'day') !== 0 && ` (${dayjs().diff(item.created_on, 'day')} days ago)`}
                            </span>
                            <span className=' flex items-center gap-1'>
                              <img src={facilityIcon} alt="facilityIcon" /> {item.facility_name}
                            </span>
                          </div>
                          {item.message_groups !== MSG_GENERAL && <>
                            {Number(item.message_or_order) === MSG_TYPE_ORDER &&
                              <>
                                <Tooltip title="Order" placement="top" arrow>
                                  <img src={showIcon} alt="" loading='lazy' decoding='async' />
                                </Tooltip>
                              </>}
                          </>
                          }
                        </div>
                      </div>
                      <div className="flex gap-3 items-center border-b py-3 flex-wrap">
                        <p className='text-[13px] font-medium text-[var(--color-424242)]'>To</p>
                        {item.receiver?.length > 0 && item.receiver.map(user => <span className='active-to'>{
                          user === NURSING_STAFF_SPACE ? LBL_NURSING_STAFF
                            : user === PROVIDER ? LBL_PROVIDERS
                              : user === PATIENTS ? LBL_PATIENTS
                                : user}</span>)}
                      </div>
                      {((loginUserRole !== USER_ROLE_PATIENTS && loginUserRole !== USER_ROLE_RELATIVES) && item.related_patient && item.related_patient?.length > 0) && <p className='text-xs my-3 text-[var(--color-212121)]'>Patient: {item.related_patient}</p>}
                      {(item.message_type === MSG_TYPE_GENERAL && messageTab === MSG_TAB_SEND_MESSAGE) && <p className='text-xs my-3 text-[var(--color-212121)]'>{LBL_GENERAL_PATIENTS}</p>}
                      <div className={'flex justify-between ' + `${((((loginUserRole !== USER_ROLE_PATIENTS && loginUserRole !== USER_ROLE_RELATIVES) && item.related_patient && item.related_patient?.length > 0) || (item.message_type === MSG_TYPE_GENERAL && messageTab === MSG_TAB_SEND_MESSAGE))) ? "" : " pt-3 "}`}>
                        {
                          Number(item.message_or_order) === MSG_TYPE_ORDER ?

                            <div className='text-[15px] text-[var(--color-212121)] flex gap-3'>
                              {item.priority === hasHighPriority && <span className='text-[var(--color-danger)]'>! </span>}
                              <ReactQuill theme="snow" value={item.content.replace(/(\r\n|\r|\n)/g, '<br>')} modules={{ toolbar: false }} className={`w-full h-full border-none `} readOnly={true} />

                            </div>
                            : <>
                              {item.priority === hasHighPriority && <span className='text-[var(--color-danger)] mt-[9px]'>!</span>}
                              <ReactQuill theme="snow" value={item.content.replace(/(\r\n|\r|\n)/g, '<br> ')} modules={{ toolbar: false }} className={`w-full h-full border-none `} readOnly={true} /></>
                        }


                        {(Number(item.message_or_order) === MSG_TYPE_ORDER && item.file_name !== "") && <div className='w-fit min-w-[40px] flex items-end'> <a href={item.file_name} download={true} target='_blank' rel="noreferrer" ><img src={pdfIcon} alt="" className='ml-3' loading='lazy' decoding='async' /></a></div>}
                      </div>
                    </div>
                  </>)}


                </div>


              </div>
              :
              messageTab === MSG_TAB_SEND_MESSAGE && <div className='w-full   flex flex-col gap-6 items-center  mt-36 '>
                <img src={patientImg} alt="" loading='lazy' decoding='async' />
                <h2 className='text-2xl font-medium text-[var(--color-424242)] tracking-[0.05px]'>No messages</h2>
              </div>
            }

            {((messageTab === MSG_TAB_SEL_MESSAGE || messageTab === MSG_TAB_SEND_MESSAGE)) &&
              <Paginations
                totalItems={paginationTotalCount}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                setCurrentPage={handlePageChange}
              />}
          </div>
        </>
      }

    </>
  )
}

export default Dashboard